import { IonDatetime, IonItem, IonLabel, isPlatform } from "@ionic/react";

interface Props {
	label?: string | null;
	value?: string | null;
	setValue?: (value: string) => void;
	displayFormat?: string;
	min?: string;
	max?: string;
	first?: boolean;
	onChange?: (text: string) => void;
}

export default function ProfileDatePickerItem(props: Props) {
	return (
		<IonItem
			lines="inset"
			key={props.label}
			style={{
				paddingTop: props.first ? "0px" : "16px",
			}}
		>
			<IonLabel position="stacked" color="primary">
				<h1>
					<b>{props.label}</b>
				</h1>
			</IonLabel>

			<IonDatetime
				displayFormat={
					props.displayFormat ? props.displayFormat : "MM/DD/YYYY"
				}
				min={props.min ? props.displayFormat : "1900-01-01"}
				max={props.max ? props.displayFormat : "2002-01-01"}
				value={props.value}
				onIonChange={(e) => {
					if (
						props.setValue &&
						e.detail.value &&
						props.value != undefined
					) {
						props.setValue(e.detail.value);

						if (props.value !== e.detail.value && props.onChange) {
							props.onChange(e.detail.value);
						}
					}
				}}
			></IonDatetime>
		</IonItem>
	);
}
