import { useRef } from "react";
import { isPlatform } from "@ionic/core";
import {
	IonHeader,
	IonContent,
	IonMenu,
	IonList,
	IonListHeader,
	IonText,
} from "@ionic/react";
import {
	listOutline,
	golfOutline,
	alarmOutline,
	calendarOutline,
	happyOutline,
	fastFoodOutline,
	beerOutline,
	journalOutline,
	peopleOutline,
	fitnessOutline,
	hammerOutline,
	documentsOutline,
	createOutline,
} from "ionicons/icons";

import NavMenuItem from "./NavMenuItem";

import "./NavMenu.css";

export default function NavMenu() {
	const menuRef = useRef<HTMLIonMenuElement>(null);

	function onMenuItemClick() {
		menuRef.current?.close();
	}

	const headerClass = "text-lg font-semibold";

	// TODO: Allow swipe if on root page and PWA
	return (
		<IonMenu
			ref={menuRef}
			side="start"
			contentId="main-content"
			swipeGesture={isPlatform("capacitor")}
		>
			<IonHeader>
				<img
					className={"nav-menu-logo"}
					src={"assets/logo.svg"}
					alt="myClarity Logo"
				/>
			</IonHeader>

			<IonContent>
				<div
					className="container"
					style={{
						height: "100%",
						display: "flex",
						flexFlow: "column nowrap",
						alignItems: "stretch",
					}}
				>
					<div
						style={{
							flex: 1,
						}}
					>
						<IonList>
							<IonListHeader>
								<IonText color={"primary"}>
									<h5 className={headerClass}>Plan</h5>
								</IonText>
							</IonListHeader>
							<NavMenuItem
								name={"Tasks"}
								icon={listOutline}
								routerLink={
									isPlatform("desktop")
										? "/tasks/tasks"
										: "/tasks"
								}
								onClick={onMenuItemClick}
							/>
							<NavMenuItem
								name={"Goals"}
								icon={golfOutline}
								routerLink={"/goals"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Routines"}
								icon={alarmOutline}
								routerLink={"/routines"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Schedule"}
								icon={calendarOutline}
								routerLink={"/schedule"}
								onClick={onMenuItemClick}
								disabled
							/>

							<IonListHeader>
								<IonText color={"primary"}>
									<h5 className={headerClass}>Mind</h5>
								</IonText>
							</IonListHeader>
							<NavMenuItem
								name={"Mood"}
								icon={happyOutline}
								routerLink={"/mood"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Journal"}
								icon={journalOutline}
								routerLink={"/journal"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Social"}
								icon={peopleOutline}
								routerLink={"/social"}
								onClick={onMenuItemClick}
								disabled
							/>

							<IonListHeader>
								<IonText color={"primary"}>
									<h5 className={headerClass}>Body</h5>
								</IonText>
							</IonListHeader>
							<NavMenuItem
								name={"Diet"}
								icon={fastFoodOutline}
								routerLink={"/diet"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Consumptions"}
								icon={beerOutline}
								routerLink={"/consumptions"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Fitness"}
								icon={fitnessOutline}
								routerLink={"/fitness"}
								onClick={onMenuItemClick}
								disabled
							/>

							<IonListHeader>
								<IonText color={"primary"}>
									<h5 className={headerClass}>Knowledge</h5>
								</IonText>
							</IonListHeader>
							<NavMenuItem
								name={"Skills"}
								icon={hammerOutline}
								routerLink={"/skills"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Records"}
								icon={documentsOutline}
								routerLink={"/records"}
								onClick={onMenuItemClick}
								disabled
							/>
							<NavMenuItem
								name={"Notes"}
								icon={createOutline}
								routerLink={"/notes"}
								onClick={onMenuItemClick}
								disabled
							/>
						</IonList>
					</div>
				</div>
			</IonContent>
		</IonMenu>
	);
}
